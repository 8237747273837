<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <button
            type="submit"
            class="btn btn-primary"
            @click="showMsgBoxEdit()"
          >
            Enregistrer
          </button>
        </div>
      </div>
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-tile mb-0">Général</h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <h5 class="form-label">Code promo</h5>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Code promo"
                  v-model="codePromoInformation.code"
                />
                <b-button
                  type="submit"
                  class="btn mt-2"
                  variant="outline-primary"
                  @click="generateCode"
                >
                  Générer un code
                </b-button>
              </div>
              <div class="mb-3">
                <h5 class="form-label">Type</h5>
                <v-select
                  style="cursor: pointer"
                  :options="typeCodePromo"
                  v-model="codePromoInformation.discount_type"
                  placeholder="Type code promo"
                  label="text"
                  input-id="value"
                  value="value"
                />
              </div>
              <div v-if="codePromoInformation.discount_type">
                <div
                  class="mb-3"
                  v-if="
                    codePromoInformation.discount_type.text ==
                    'Remise panier fixe'
                  "
                >
                  <h5 class="form-label" for="ecommerce-product-price">
                    Valeur code promo
                  </h5>
                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="text"
                      name="price"
                      v-model="codePromoInformation.amount"
                      @keypress="stripTheGarbage($event)"
                      @blur="formaValeurCodePromo()"
                      required
                    />
                    <span class="currency-symbol">TND </span>
                  </div>
                </div>
                <div
                  class="mb-3"
                  v-if="
                    codePromoInformation.discount_type.text ==
                    'Remise en pourcentage'
                  "
                >
                  <h5 class="form-label" for="ecommerce-product-price">
                    Valeur code promo
                  </h5>
                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="number"
                      name="price"
                      v-model="codePromoInformation.amount"
                      min="0"
                      max="100"
                      required
                    />
                    <span class="currency-symbol">% </span>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <h5>Date d’expiration du code</h5>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="fr"
                  v-model="codePromoInformation.date_expires"
                ></b-form-datepicker>
              </div>
              <div class="mb-3">
                <h5>Autoriser l’expédition gratuite</h5>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  v-model="codePromoInformation.free_shipping"
                >
                  Cocher cette case si le code promo inclut l’expédition
                  gratuite.
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- Second column -->
        <div class="col-12 col-lg-6">
          <div class="card mb-1">
            <div class="card-header">
              <h3 class="card-title mb-0">Réstriction d'usage</h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <h5 class="form-label">
                  Dépense minimale
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir le montant minimum
                    autoriser à utiliser le code promo"
                    variant="gradient-primary"
                  />
                </h5>

                <div class="currency-input">
                  <input
                    class="form-control"
                    type="text"
                    name="price"
                    placeholder="Dépense minimale"
                    v-model="codePromoInformation.minimum_amount"
                    @keypress="stripTheGarbage($event)"
                    @blur="formaDepenseMinimum()"
                    required
                  />
                  <span class="currency-symbol">TND </span>
                </div>
              </div>
              <div class="mb-3">
                <h5 class="form-label">
                  Dépense maximale
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir le montant maximale autoriser à utiliser le code promo"
                    variant="gradient-primary"
                  />
                </h5>
                <div class="currency-input">
                  <input
                    class="form-control"
                    type="text"
                    name="price"
                    placeholder="Dépense maximale"
                    v-model="codePromoInformation.maximum_amount"
                    @keypress="stripTheGarbage($event)"
                    @blur="formaDepenseMaximum()"
                    required
                  />
                  <span class="currency-symbol">TND </span>
                </div>
              </div>
              <div class="mb-3">
                <h5>Utilisation individuelle uniquement</h5>
                <b-form-checkbox
                  id="checkbox-2"
                  name="checkbox-2"
                  v-model="codePromoInformation.individual_use"
                >
                  Cochez cette case si le code promo ne peut être utilisé
                  conjointement avec d’autres codes promo.
                </b-form-checkbox>
              </div>
              <div class="mb-3">
                <h5>Exclure les articles en promo</h5>
                <b-form-checkbox
                  id="checkbox-3"
                  name="checkbox-3"
                  v-model="codePromoInformation.exclude_sale_items"
                >
                  Cochez cette case si le code promo ne doit pas être appliqué
                  aux articles en promo.
                </b-form-checkbox>
              </div>
              <div class="mb-2">
                <h3>Limites d'utilisation</h3>
              </div>

              <div class="mb-3">
                <h5 class="form-label">
                  Limite d’utilisation par code
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir combien de fois ce code promo peut il ètre utilisé avant de
                    ne plus etre valide"
                    variant="gradient-primary"
                  />
                </h5>

                <input
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Limite utilisation"
                  v-model="codePromoInformation.usage_limit"
                />
              </div>
              <div class="mb-3">
                <h5 class="form-label">
                  Limite d’utilisation par utilisateur
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir Combien de fois ce code promo peut il ètre utilisé par un
                    utilisateur"
                    variant="gradient-primary"
                  />
                </h5>
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  placeholder="Limite utilisation par utilisateurs"
                  v-model="codePromoInformation.usage_limit_per_user"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BFormDatepicker,
  BFormCheckbox,
  BButton,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import websiteURL from "@/websiteURL";
import axios from "axios";
import { formatDinar } from "../../views/CodesPromo/CodePromoFunction";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BFormCheckbox,
    BButton,
    vSelect,
    BFormDatepicker,
    VBTooltip,
    BOverlay,
  },
  data() {
    return {
      typeCodePromo: [
        { value: "percent", text: "Remise en pourcentage" },
        { value: "fixed_cart", text: "Remise panier fixe" },
      ],
      codePromoInformation: [],
      boxMsgEdit: "",
      loading: false,
      codePromoType: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/coupons/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.codePromoInformation = response.data;
        if (vm.codePromoInformation.discount_type == "percent") {
          vm.codePromoType.value = vm.codePromoInformation.discount_type;
          vm.codePromoType.text = "Remise en pourcentage";
        } else if (vm.codePromoInformation.discount_type == "fixed_cart") {
          vm.codePromoType.value = vm.codePromoInformation.discount_type;
          vm.codePromoType.text = "Remise panier fixe";
        }
        vm.codePromoInformation.discount_type = vm.codePromoType;
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    async formaValeurCodePromo() {
      await formatDinar(this.codePromoInformation.amount);
    },
    async formaDepenseMinimum() {
      await formatDinar(this.codePromoInformation.minimum_amount);
    },
    async formaDepenseMaximum() {
      await formatDinar(this.codePromoInformation.maximum_amount);
    },
    //Permet de generer un code aléatoirement
    generateCode() {
      this.codePromoInformation.code = Math.random()
        .toString(36)
        .toUpperCase()
        .slice(2, 10);
    },
    //Permet la modification des informations du code promo
    async editCodePromo() {
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/coupons/${this.codePromoInformation.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          code: this.codePromoInformation.code,
          amount: this.codePromoInformation.amount,
          discount_type: this.codePromoInformation.discount_type.value,
          date_expires: this.codePromoInformation.date_expires,
          free_shipping: this.codePromoInformation.free_shipping,
          minimum_amount: this.codePromoInformation.minimum_amount,
          maximum_amount: this.codePromoInformation.maximum_amount,
          individual_use: this.codePromoInformation.individual_use,
          exclude_sale_items: this.codePromoInformation.exclude_sale_items,
          usage_limit: this.codePromoInformation.usage_limit,
          usage_limit_per_user: this.codePromoInformation.usage_limit_per_user,
        };
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.editConfirmeToastMsg("info");
      } catch (error) {
        alert(
          "Une erreur s'est produite lors de la modification du code promo",
          error
        );
      }
    },
    //Message de confirmation de modification
    showMsgBoxEdit() {
      this.boxMsgEdit = "";
      this.$bvModal
        .msgBoxConfirm(
          "Êtes-vous sûr de vouloir mettre à jour le code promo ?",
          {
            title: "Confirmation",
            size: "md",
            okVariant: "primary",
            okTitle: this.loading ? "Loading..." : "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          this.boxMsgEdit = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.editCodePromo().then(() => {
                this.loading = false;
              });
            }
          }
        });
    },
    editConfirmeToastMsg(variant = null) {
      this.$bvToast.toast("Le code promo a bien été mise à jour", {
        title: `Code promo ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
};
</script>
<style lang="scss">
// Style
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
