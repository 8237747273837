import axios from "axios";
import websiteURL from "@/websiteURL";

export async function getAllCodePromo(perPage, curentPage) {
  const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/coupons";
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.get(apiUrl, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
      params: {
        per_page: perPage,
        page: curentPage,
      },
    });
    const codePromo = response.data;
    const totalCodePromo = response.headers["x-wp-total"];
    const codePromoInfo = { codePromo, totalCodePromo };

    return codePromoInfo;
  } catch (error) {
    // Handle errors, e.g., show an error message
    alert("Error de récuperation des codes promo:", error);
  }
}
export async function deleteCodePromo(idCodePromo) {
  const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/coupons/${idCodePromo}`;
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.delete(apiUrl, {
      params: {
        force: true,
      },
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });
  } catch (error) {
    alert(
      "Erreur lors de la suppression du code promo, veuillez réessayer plus tard",
      error
    );
  }
}
export async function formatDinar(codePromoValue) {
  if (codePromoValue != "") {
    var num = codePromoValue;

    num = Number(num);

    var countDecimals = function (value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    };

    var decimal = countDecimals(num);

    if (decimal < 2) {
      num = num.toFixed(2);
    }

    if (decimal > 2) {
      num = num.toFixed(decimal);
    }

    if (parseInt(num) < 1) {
      num = "." + String(num).split(".")[1];
    }

    codePromoValue = num;
    return codePromoValue;
  }
}
